// Include jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(() => {
	NProgress.inc();
}, 1000);
window.addEventListener("load", () => {
	NProgress.done();
	clearInterval(interval);
});

/******************************** Global functions *************************************/

// Dom ready function
window.domReady = function (callback) {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
};

// Get cookie function
window.getCookie = function (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "undefined";
};

// Set cookie function
window.setCookie = function (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	return cvalue;
};

// Get json data
window.getJsonData = function (id, clear) {
	var $el = document.getElementById(id);
	if ($el) {
		var json = $el.textContent || $el.innerText;
		var data = JSON.parse(json);
		if (clear) {
			$el.innerHTML = "";
		}
		return data;
	} else {
		return undefined;
	}
};

// Decode html
window.decodeHtml = function (html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
};

/******************************** App specific functions *************************************/

// Print friendly change
const $printLink = document.querySelectorAll(
	".page-tools a.print,.detail-tools a.print"
);
if ($printLink) {
	$printLink.forEach((el) => {
		el.parentNode.style.display = "inline";
		el.innerText = "print";
		el.title = "print";
		el.addEventListener("click", (e) => {
			e.preventDefault();
			window.print();
		});
	});
}

/******************************** JQuery functions *************************************/

$(() => {
	/* ---- START HOME PAGE CHANGES ---- */
	if ($("body div").hasClass("home")) {
		if ($("body").hasClass("geo")) {
			/*  Makes the three columns on geo homepage clickable buttons */
			$(".column #left-column").appendTo(".column.yellow > a");
			$(".column #middle-column").appendTo(".column.blue > a");
			$(".column #right-column").appendTo(".column.green > a");
			/*  Replaces the hard-coded links in the columns with ones that match the links in the wysiwygs */
			var leftLink = $("#left-column h2 a:last-of-type").attr("href");
			var midLink = $("#middle-column h2 a:last-of-type").attr("href");
			var rightLink = $("#right-column h2 a:last-of-type").attr("href");
			$(".column.yellow a:eq(0)").attr("href", leftLink);
			$(".column.blue a:eq(0)").attr("href", midLink);
			$(".column.green a:eq(0)").attr("href", rightLink);
		}
	}

	/* ---- END HOME PAGE CHANGES ---- */
	/*  Removes login and register links from practice area headers when user is signed in  */
	if ($("body").hasClass("nonprofit") || $("body").hasClass("geo")) {
		if ($("#nav li.signout").length) {
			$("#nav li.signin,#nav li.login").remove();
			$("#nav li.join,#nav li.register").remove();
		}
	}

	/*  Adds dropdown nav menu to header  */

	/*if ($("#nav ul:eq(0)").hasClass("js-slide-hidden") == false) {
		$("#nav ul:eq(0)").addClass("js-slide-hidden");
	}
	/*
	if ($("body").hasClass("geo")) {
		$(
			'<ul class="dropdown-content"><li><a href="/nonprofit">Nonprofit Resource Center</a></li><li><a href="/nonprofit/volunteer">Pro Bono Volunteer Guide</a></li><li><a href="/calendar">Calendar</a></li><li><a href="/news">News</a></li><li><a href="/legaldirectory">Legal Directory</a></li></ul>'
		).appendTo("#nav li:first-child");
		$("#nav .js-slide-hidden:eq(0) li:eq(0)").wrapInner(
			'<div class="dropdown"></div>'
		);
	} else
	*/
	/*if ($("body").hasClass("nonprofit")) {
		$(
			'<ul class="dropdown-content"><li><a href="/nonprofit/library">Library</a></li><li><a href="/nonprofit/calendar">Calendar</a></li><li><a href="/nonprofit/alerts">Alerts</a></li><li><a href="/nonprofit/webinars">Webinars</a></li><li><a href="/nonprofit/podcasts">Podcasts</a></li><li><a href="/nonprofit/jobs">Jobs</a></li><li><a href="/nonprofit/news">News</a></li><li><a href="/nonprofit/roster">Members</a></li><li><a href="/nonprofit/legaldirectory">Legal Directory</a></li></ul>'
		).appendTo("#nav li:first-child");
		$("#nav .js-slide-hidden:eq(0) li:eq(0)").wrapInner(
			'<div class="dropdown"></div>'
		);
	} else if ($("body").hasClass("probono")) {
		$(
			'<ul class="dropdown-content"><li><a href="/probono/library">Library</a></li><li><a href="/probono/calendar">Calendar</a></li><li><a href="/probono/volunteer">Volunteer Guide</a></li><li><a href="/probono/webinars">Webinars</a></li><li><a href="/probono/podcasts">Podcasts</a></li><!--<li><a href="/probono/jobs">Jobs</a></li>--><li><a href="/probono/news">News</a></li><li><a href="/probono/members">Members</a></li><!--<li><a href="/probono/alerts">Alerts</a></li>--></ul>'
		).appendTo("#nav li:first-child");
		$("#nav .js-slide-hidden:eq(0) li:eq(0)").wrapInner(
			'<div class="dropdown"></div>'
		);
	}
	if (
		$("#nav .dropdown:eq(0) ul:eq(0)").hasClass("js-slide-hidden") == false
	) {
		$("#nav .dropdown:eq(0) ul:eq(0)").addClass("js-slide-hidden");
	}*/

	/*  Removes featured on section from most inner pages  */
	if ($("body").hasClass("inner") || $("body").hasClass("tool")) {
		if (
			$("body").hasClass("about") == false &&
			$("body").hasClass("projects") == false &&
			$("body").hasClass("donate") == false
		) {
			$("#featured_on").remove();
		}
	}

	/*  Removes partners section from most inner pages  */
	if ($("body").hasClass("inner") || $("body").hasClass("tool")) {
		if (
			$("body").hasClass("about") == false &&
			$("body").hasClass("projects") == false &&
			$("body").hasClass("donate") == false &&
			$("body").hasClass("legaldirectory") == false
		) {
			$("#partners_and_sponsors").remove();
		}
	}

	/*  Removes the commas from the news and calendar items  */
	if ($("body").hasClass("calendar") || $("body").hasClass("news")) {
		$.each($(".feed.offset li"), function () {
			var itemText = $(this).html();
			sansComma = itemText.replace(",", "");
			$(this).html(sansComma);
		});
	}

	/*  Rearranges alert item detail page to mimic library v2 item detail pages  */
	if (
		$("body").hasClass("alerts") &&
		$("#main #content #m-header + div").hasClass("content-detail")
	) {
		$(".props li:last-child").addClass("alert-attachment");
		$(".alert-attachment").insertBefore(".props li:first-child");
		$(".description").insertBefore(".props");
	}

	$("#vr-signup").insertBefore("#footer ul:eq(0)");

	/*  Removes bars in news tool header lists  */
	if (
		$("body").hasClass("news") &&
		$("#main #content #m-header").hasClass("head-article") == false &&
		$("#main #content #m-header").hasClass("head-article-form") == false
	) {
		var itemText = $(".tool-options.top.HighlightMid:eq(0)").html();
		var sansBar = itemText.replace("|", "");
		$(".tool-options.top.HighlightMid:eq(0)").html(sansBar);
		do {
			var str = $(".tool-options.bottom.HighlightLight:eq(0)").html();
			var res = str.replace("|", "");
			$(".tool-options.bottom.HighlightLight:eq(0)").html(res);
		} while (res.indexOf("|") >= 0);
	}

	/*  Makes icon boxes on inner pages clickable buttons  */
	if (
		document.URL.indexOf("/about/") >= 0 ||
		$("body").hasClass("projects") ||
		(($("body").hasClass("nonprofit") || $("body").hasClass("probono")) &&
			$("body div").hasClass("home"))
	) {
		var box = document
			.getElementById("toolicons")
			.getElementsByClassName("box");
		for (var i = 0; i < box.length; i++) {
			var link = box[i]
				.getElementsByTagName("h2")[0]
				.getElementsByTagName("a")[0]
				.getAttribute("href");
			var thisBox = box[i];
			var newLink = '<a href="' + link + '"></a>';
			$(thisBox).wrapAll(newLink);
		}
	}
});
